




























































































import {
  BindedChannelGreetingProtection
} from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection";
import BaseProtectionTypeFields from "./BaseProtectionTypeFields.vue";
import { InputSetups } from "@/mixins/input-setups";
import { ChatConfig } from "@/includes/types/Chat/types";

import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue';
import { atSignedLogin, tgLoginLink } from "piramis-base-components/src/shared/utils/tgLoginHelpers";

import { Component, VModel, Mixins, Prop } from 'vue-property-decorator';
import BindChannel from "@/components/Modules/components/facecontrol/BindChannel.vue";
import Api from "@/includes/logic/Api";
import { errorNotification } from "@/includes/NotificationService";

@Component({
  components: {
    BindChannel,
    TextInput,
    BaseProtectionTypeFields
  }
})
export default class BindedChannelGreetingProtectionView extends Mixins(InputSetups) {
  @VModel() model!: BindedChannelGreetingProtection

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  isBindModalOpen = false

  get bindChannelInfo(): string {
    if(this.$store.state.chatState.chat.brand) {
      return this.$t('bind_channel_post_alert_greeting', { '0': atSignedLogin(this.$store.state.chatState.chat.brand.botname), '1': tgLoginLink(this.$store.state.chatState.chat.brand.botname) }).toString()
    } else {
      return this.$t('bind_channel_post_alert_greeting', { '0': `@ChatKeeperBot`, '1': `https://t.me/chatkeeperbot` }).toString()
    }
  }

  doBindChannel(channel_id: number): void {
    Api.bindChatChannel('tg', {
      chat_id: this.$store.getters.chatId,
      channel: channel_id
    })
      .then(({ data }: { data: { chat: ChatConfig, binded_channel_invite_link: string } }) => {
        this.bindFinish({
          channelId: data.chat.binded_channel,
          invite_link: data.binded_channel_invite_link
        })

        this.$store.commit('EXEC_CALLBACK_IN_STORE', {
          'callback': () => this.$store.state.chatState.chat.binded_channel = data.chat.binded_channel
        })
      })
      .catch(errorNotification)
      .finally(() => this.isBindModalOpen = false)
  }

  unbindChannel(): void {
    this.$confirm({
      title: this.$t('channel_binding_unbind').toString(),
      content: this.$t('channel_binding_sure').toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        Api.unbindChatChannel('tg', { chat_id: this.$store.state.chatState.chat.chat_id })
          .then(({ data }) => {
            this.$store.commit('EXEC_CALLBACK_IN_STORE', {
              'callback': () => this.$store.state.chatState.chat.binded_channel = data.chat.binded_channel
            })
          })
          .catch(errorNotification)
          .finally(() => this.isBindModalOpen = false)
      }
    })
  }

  bindFinish(result: { channelId: ChatConfig['binded_channel'], invite_link: string }) {
    const { channelId, invite_link } = result

    if (invite_link) {
      this.model.invite_link = invite_link
    }
  }
}

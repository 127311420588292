






















































































import { InputSetups } from "@/mixins/input-setups";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import {
  GreetingProtectionTypeEnum,
  GreetingStringStrictModeEnum
} from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/types";

import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";
import { SelectOption } from "piramis-base-components/src/logic/types";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";

import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';

@Component({
  data() {
    return {
      GreetingProtectionTypeEnum
    }
  },
  components: {
    SelectInput,
    NestedContent,
    TimeUnitInput,
    SwitchInput,
  }
})
export default class BaseProtectionTypeFields extends Mixins(InputSetups, TariffsTagsHelper) {
  @Prop() protectionType!: GreetingProtectionTypeEnum

  @PropSync('removeNotConfirmUsersTimeout') removeNotConfirmUsersTimeoutModel!: number | undefined

  @PropSync('banUnverifiedUsersTime') banUnverifiedUsersTimeModel!: number | undefined

  @PropSync('removeHellowAfterButtonPressed') removeHellowAfterButtonPressedModel!: boolean | undefined

  @PropSync('removeHellowButton') removeHellowButtonModel!: boolean | undefined

  @PropSync('strict_mode') mode!: GreetingStringStrictModeEnum | undefined

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  get strictMode() {
    return this.mode === GreetingStringStrictModeEnum.Restrict
  }

  set strictMode(value: boolean) {
    if (value) {
      this.mode = GreetingStringStrictModeEnum.Restrict
    } else {
      this.mode = GreetingStringStrictModeEnum.AllowWriteMessages
    }
  }

  get strictModeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('greeting_protection_strict_mode_restrict').toString(),
        value: GreetingStringStrictModeEnum.Restrict
      },
      {
        label: this.$t('greeting_protection_strict_mode_allow_write_messages').toString(),
        value: GreetingStringStrictModeEnum.AllowWriteMessages
      },
    ]
  }
}

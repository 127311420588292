





























import {
  StandardGreetingProtection
} from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection";
import { InputSetups } from "@/mixins/input-setups";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import BaseProtectionTypeFields from "./BaseProtectionTypeFields.vue";

import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";

import { Component, VModel, Mixins, Prop } from 'vue-property-decorator';

@Component({
  components: {
    BaseProtectionTypeFields,
    TimeUnitInput,
    TextInput,
    SwitchInput,
    NestedContent
  }
})
export default class StandardGreetingProtectionView extends Mixins(InputSetups, TariffsTagsHelper) {
  @VModel() model!: StandardGreetingProtection

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean
}

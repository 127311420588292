














































import {
  VariableCaptchaGreetingProtection
} from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection";
import BaseProtectionTypeFields from "./BaseProtectionTypeFields.vue";
import { InputSetups } from "@/mixins/input-setups";

import { SelectOption } from "piramis-base-components/src/logic/types";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import ChipsInput from "piramis-base-components/src/components/Pi/fields/ChipsInput/ChipsInput.vue";

import { Component, VModel, Mixins, Prop } from 'vue-property-decorator';

@Component({
  components: {
    ChipsInput,
    SelectInput,
    BaseProtectionTypeFields
  }
})
export default class VariableCaptchaGreetingProtectionView extends Mixins(InputSetups) {
  @VModel() model!: VariableCaptchaGreetingProtection

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  get variantsOptions(): Array<SelectOption> {
    return this.model.variants.map(value => ({
      label: value,
      value,
    }))
  }
}

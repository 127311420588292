










































import {
  InviteValidationGreetingProtection
} from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection";
import BaseProtectionTypeFields
  from "@/components/Modules/modules/Greeting/components/ProtectionTypes/BaseProtectionTypeFields.vue";
import { InputSetups } from "@/mixins/input-setups";

import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";

import { Component, VModel, Mixins, Prop } from 'vue-property-decorator';

@Component({
  components: {
    TextInput,
    NumberInput,
    BaseProtectionTypeFields
  }
})
export default class InviteValidationGreetingProtectionView extends Mixins(InputSetups) {
  @VModel() model!: InviteValidationGreetingProtection

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean
}

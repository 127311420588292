














import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import {
  MathCaptchaGreetingProtection
} from "@/includes/logic/Modules/models/modules/Greeting/NewUsersHandlerModule/protection";
import BaseProtectionTypeFields from "./BaseProtectionTypeFields.vue";

@Component({
  components: {
    BaseProtectionTypeFields
  }
})
export default class MathCaptchaGreetingProtectionView extends Vue {
  @VModel() model!: MathCaptchaGreetingProtection

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean
}

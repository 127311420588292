var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"none-greeting-protection-view"},[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'accept_join_requests',
        'hasAnchor': true,
        'disabled': _vm.disabled
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
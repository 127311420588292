import { render, staticRenderFns } from "./BindedChannelGreetingProtectionView.vue?vue&type=template&id=7b708f4c&scoped=true&"
import script from "./BindedChannelGreetingProtectionView.vue?vue&type=script&lang=ts&"
export * from "./BindedChannelGreetingProtectionView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b708f4c",
  null
  
)

export default component.exports